import React, { useEffect, useState } from "react"
import { useParams, Navigate, Link } from "react-router-dom"
import * as Sentry from "@sentry/react"
import Loading from "../../components/loading"
import Api from "../../network/api"
import { subdomainIsWidget } from "../../helpers/subdomain"

const BrandRedirect = () => {
  const { code, code2, brandCode } = useParams()
  const finalCode = code2 === undefined ? code : `${code}/${code2}`
  const token = localStorage.getItem("token")
  const publicKey = localStorage.getItem("publicKey")
  const customerId = localStorage.getItem("customerId")
  const externalId = localStorage.getItem("externalId")

  Sentry.captureEvent({
    message: "Brand redirection parameters",
    extra: {
      code,
      code2,
      brandCode,
      finalCode,
      token,
      publicKey,
      customerId,
      externalId,
    },
  })

  if (subdomainIsWidget()) {
    let url = `https://connect.vivanta.io/redirect/${brandCode}/${finalCode}`
    window.location.href = url
  }

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  let brandId = 0
  if (brandCode === "fitbit") brandId = 1
  if (brandCode === "withings") brandId = 2
  if (brandCode === "polar") brandId = 3
  if (brandCode === "garmin") brandId = 4
  if (brandCode === "whoop") brandId = 7
  if (brandCode === "googlefit" || brandCode === "google-fit") brandId = 5

  useEffect(() => {
    if (loading) {
      const api = new Api()
      api
        .saveAuthCode(brandId, finalCode)
        .then(result => {
          Sentry.captureEvent({
            message: "Brand redirection result",
            extra: { result },
          })
          if (result) {
            setLoading(false)
            setSuccess(true)
          } else {
            setLoading(false)
            setSuccess(false)
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          setLoading(false)
        })
    }

    return () => {}
  }, [])

  if (success)
    return <Navigate to={`/integration-success/${brandCode}`} replace={true} />

  if (loading) return <Loading />

  return (
    <>
      <h1>Something went wrong</h1>
      <p>There was an error processing your request</p>
      <Link to="/start" className="btn btn-primary btn-block">
        Try again
      </Link>
    </>
  )
}

export default BrandRedirect
